import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import { useForm, ValidationError } from '@formspree/react';

const Newsletter = (props) => {

  const [state, handleSubmit] = useForm("mlearywe");
  const ResponseMsg = () => {
    if (state.succeeded) {
      return (
        <div className="form-group my-4 text-center">
          <p>Thanks for reaching out!</p>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {/* <!-- Newsletter section --> */}
      <div
        id="CPGContact"
        {...props}
        className="newsletter-section pt-10 pb-10 py-lg-22 border-bottom bg-default-4"
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="7">
              <div className="section-title text-center mb-12">
                <div className="circle-78 bg-green text-white gr-text-4 mx-auto mb-8">
                  <i className="fas fa-envelope"></i>
                </div>
                <h2 className="title gr-text-4 mb-4">Schedule a Demo</h2>
                <p className="px-8 gr-text-8 mb-0">
                  Reach out today to make your digital marketing what it was
                  meant to be!
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="7" md="8" sm="11">
              <div className="subscribe-form mb-8">
                <form
                  className="cta-form bg-default light-mode rounded-10 px-12 py-10"
                  onSubmit={handleSubmit}
                  id="contact-form"
                  action="https://formspree.io/f/mlearywe"
                  method="POST"
                  name="website-contact"
                  data-netlify-honeypot="bot-field"
                >
                  <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                    <label htmlFor="ctaName">Name </label>
                    <input
                      type="text"
                      className="form-control gr-text-11"
                      id="ctaName"
                      placeholder=""
                      required=""
                      name="name"
                      required
                    />
                    <ValidationError
                      prefix="name"
                      field="name"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                    <label htmlFor="ctaEmail">Email </label>
                    <input
                      type="email"
                      className="form-control gr-text-11"
                      id="ctaEmail"
                      placeholder=""
                      required=""
                      name="_replyto"
                      required
                    />
                    <ValidationError
                      prefix="Email"
                      field="_replyto"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                    <label htmlFor="ctaPhone">Phone </label>
                    <input
                      type="text"
                      className="form-control gr-text-11"
                      id="ctaPhone"
                      placeholder=""
                    />
                    <ValidationError
                      prefix="Phone"
                      field="phone"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="message"
                      className="gr-text-11 font-weight-bold text-blackish-blue"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      className="form-control gr-text-11 border-gray-3 gr-textarea-height"
                      required
                    ></textarea>
                    <ValidationError
                      prefix="Message"
                      field="message"
                      errors={state.errors}
                    />
                  </div>
                  <Button type="submit" className="mt-0 rounded-8 w-100">
                    Get In Touch
                  </Button>
                  <ResponseMsg />
                </form>
              </div>
              <div className="form-bottom excerpt text-center">
                <p className="gr-text-11 gr-text-color-opacity mb-0">
                  We’ll never share your details with third parties.
                  <br className="d-none d-xs-block" />
                  View our Privacy Policy for more info.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Newsletter;
